import axios from '../libs/axios';

// 次卡类型信息
const timesCardInfoById = (id) => {
  return axios.get(`/api/trd/timesCard/info/${id}`);
};
// 次卡计费信息
const timesCardRuleById = (id) => {
  return axios.get(`/api/trd/timesCard/rule/info/${id}`);
};

// 次卡类型删除
const deleteTimesCardById = (id) => {
  return axios.get(`/api/trd/timesCard/delete/${id}`);
};
// 启用或者禁用
const timesCardStatus = (data) => {
  return axios.post(`/api/trd/timesCard/status/${data.timesCardId}/${data.status}`);
};
// 启用或者禁用
const timesCardRuleStatus = (data) => {
  return axios.post(`/api/trd/timesCard/rule/status/${data.timesCardRuleId}/${data.status}`);
};
// 次卡类型保存/修改
const timesCardSubmit = (data) => {
  return axios.post('/api/trd/timesCard/save', data);
};
// 次卡计费规则保存/修改
const timesCardRuleSubmit = (data) => {
  return axios.post('/api/trd/timesCard/rule/save', data);
};
// 次卡类型列表
const timesCardList = (data) => {
  return axios.get('/api/trd/timesCard/list', data);
};
// 次卡计费规则列表
const timesCardRuleList = (data) => {
  return axios.get('/api/trd/timesCard/rule/list', data);
};
// 次卡计费规则可用列表
const timesCardRuleUsableList = (id) => {
  return axios.get(`/api/trd/timesCard/rule/usable/${id}`);
};
// 小程序显示/隐藏
const ruleMinShow = (data) => {
  return axios.post('/api/trd/timesCard/rule/minShow', data);
};
// 次卡人员列表
const timesCardPersonList = (memberId) => {
  return axios.get(`/api/trd/timesCard/person/${memberId}`);
};
// 次卡充值列表
const timesCardRechargeList = (data) => {
  return axios.get('/api/trd/recharge/timesCard/list', data);
};
// 提交充值规则
const timesCardRechargeSubmit = (data) => {
  return axios.post('/api/trd/recharge/timesCard/save', data);
};
// 提交充值规则
const timesCardPersonReduce = (data) => {
  return axios.post('/api/trd/timesCard/person/reduce', data);
};
export {
  timesCardStatus,
  timesCardRuleStatus,
  deleteTimesCardById,
  timesCardInfoById,
  timesCardRuleById,
  timesCardSubmit,
  timesCardRuleSubmit,
  timesCardList,
  timesCardRuleList,
  timesCardRuleUsableList,
  ruleMinShow,
  timesCardPersonList,
  timesCardRechargeList,
  timesCardRechargeSubmit,
  timesCardPersonReduce
};
